import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { EngageModel, ModelList } from 'app/models';
import Store from 'app/stores/Store';

export class EngageStore extends Store<EngageModel> {
  @observable timezones = new ModelList<EngageModel>(EngageModel);

  @observable isCreatingEngage = false;
  @action setIsCreatingEngage = (isCreatingEngage: boolean): boolean =>
    (this.isCreatingEngage = isCreatingEngage);

  constructor() {
    super();
    EngageModel._store = this;
  }

  async createEngage(
    orgId: number,
    entityId: number,
    entityType: string,
    cadence: string,
    sendWeekday: number,
    timezone: string
  ): Promise<void> {
    try {
      const url = ServerRouteHelper.api.engage.create(orgId);

      const config = {
        url,
        data: {
          entity_id: entityId,
          entity_type: entityType,
          cadence,
          send_weekday: sendWeekday,
          timezone,
        },
        showGenericError: true,
      };

      this.setIsCreatingEngage(true);

      await this.apiService.newPost(config);
    } finally {
      this.setIsCreatingEngage(false);
    }
  }
}

export default EngageStore;
