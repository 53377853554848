import { observable } from 'mobx';

import EngageStore from 'app/stores/EngageStore';

import Model from './Model';

export class EngageModel extends Model {
  static _store: EngageStore;

  @observable entity_id: number;
  @observable entity_type: string;
  @observable cadence: string;
  @observable send_weekday: number;
  @observable timezone: string;
  @observable started_at: string;
  @observable paused_at?: string;
  @observable created_at: string;
}

export default EngageModel;
